import Swiper from 'swiper';

window.onload = function(){

	let windowW = 0;
	let windowH = 0;

	let btn_lang = document.getElementsByClassName('btn_lang');
	let jp = document.getElementsByClassName('jp');
	let en = document.getElementsByClassName('en');
	let jp_i = document.getElementsByClassName('jp_i');
	let en_i = document.getElementsByClassName('en_i');
	let btn_start = document.getElementById('btn_start');
	let btn_end = document.getElementById('btn_end');
	let wrapper = document.getElementById('wrapper');
	let game = document.getElementById('game');
	let header = document.getElementById('header');
	let btn_menu = document.getElementById('btn_menu');
	let pc_view = document.getElementById('pc_view');
	let body = document.getElementsByTagName('body')[0];
	let btn_float = document.getElementsByClassName('btn_float');
	let float_pnl = document.getElementsByClassName('float_pnl');
	let btn_float_close = document.getElementsByClassName('btn_float_close');
	let btn_next = document.getElementsByClassName('btn_next');
	let pnl_bg = document.getElementsByClassName('pnl_bg');
	let btn_menu_item = document.getElementsByClassName('btn_menu_item');

	let ua = getUA();
	setState();
	setEvent();

	function setState(){
		checkUA();
		toggleLang("jp");
	}

	function setEvent(){
		for(let i = 0; i < btn_lang.length; i++) {
			btn_lang[i].addEventListener('click', clickLang, false);
		}
		for(let i = 0; i < btn_float.length; i++) {
			btn_float[i].addEventListener('click', showFloat, false);
		}
		for(let i = 0; i < btn_float_close.length; i++) {
			btn_float_close[i].addEventListener('click', banishFloat, false);
		}
		for(let i = 0; i < pnl_bg.length; i++) {
			pnl_bg[i].addEventListener('click', banishFloat, false);
		}
		window.onresize = resize;
		resize();
	}

	function resize(){
		windowW = window.outerWidth;
		windowH = window.innerHeight;
		console.log(windowW,windowH);
		if(windowW > windowH){
			for(let i = 0; i < float_pnl.length; i++) {
				float_pnl[i].classList.add("h");
			}
			game.classList.add("h");
		}else{
			for(let i = 0; i < float_pnl.length; i++) {
				float_pnl[i].classList.remove("h");
			}
			game.classList.remove("h");
		}
	}

	function checkUA(){
		if(!ua["sp"]){
			body.classList.add("pc");
			wrapper.classList.add("hide");
			pc_view.classList.remove("hide");
		}
	}

	function clickLang(){
		let current = this.getAttribute("data-lang");
		let target = "";
		if(current == "jp"){
			target = "en";
			for(let i = 0; i < float_pnl.length; i++) {
				float_pnl[i].classList.add("en");
			}
			for(let i = 0; i < btn_lang.length; i++) {
				btn_lang[i].setAttribute("data-lang","en");
			}
		}else{
			target = "jp";
			for(let i = 0; i < float_pnl.length; i++) {
				float_pnl[i].classList.remove("en");
			}
			for(let i = 0; i < btn_lang.length; i++) {
				btn_lang[i].setAttribute("data-lang","jp");
			}
		}
		toggleLang(target);
	}
	function toggleLang(target){
		if(target == "jp"){
			for(let i = 0; i < jp_i.length; i++) {
				jp_i[i].classList.remove("passive");
			}
			for(let i = 0; i < en_i.length; i++) {
				en_i[i].classList.add("passive");
			}
			for(let i = 0; i < jp.length; i++) {
				jp[i].classList.remove("passive");
			}
			for(let i = 0; i < en.length; i++) {
				en[i].classList.add("passive");
			}
		}else{
			for(let i = 0; i < jp_i.length; i++) {
				jp_i[i].classList.add("passive");
			}
			for(let i = 0; i < en_i.length; i++) {
				en_i[i].classList.remove("passive");
			}
			for(let i = 0; i < jp.length; i++) {
				jp[i].classList.add("passive");
			}
			for(let i = 0; i < en.length; i++) {
				en[i].classList.remove("passive");
			}
		}
	}

	function showFloat(){
		let target = document.getElementById("pnl_"+this.getAttribute("data-target"));
		target.classList.remove("banish");
	}
	
	function banishFloat(e){
		let target = document.getElementById("pnl_"+this.getAttribute("data-target"));
		target.classList.add("banish");
	}

	const swiper_atn = new Swiper("#slide_atn", {
        pagination: {
          el: ".page_atn",
        },
	});
	swiper_atn.on('slideChangeTransitionStart', function (e) {
		let pnl_atn_num = document.getElementById('pnl_atn_num');
		pnl_atn_num.innerText = swiper_atn.activeIndex+1;
	});
	const swiper_howto = new Swiper("#slide_howto", {
        pagination: {
          el: ".page_howto",
        },
	});
	swiper_howto.on('slideChangeTransitionStart', function (e) {
		let pnl_howto_num = document.getElementById('pnl_howto_num');
		pnl_howto_num.innerText = swiper_howto.activeIndex+1;
	});
	const slides = {"atn":swiper_atn,"howto":swiper_howto};

	for(let i = 0; i < btn_next.length; i++) {
    	btn_next[i].addEventListener('click', slideNext, false);
	}
	function slideNext(e){
		slides[this.getAttribute("data-target")].slideNext();
	}

	
	btn_menu.addEventListener('click', toggleMenu, false);
	function toggleMenu(){
		if(!btn_menu.classList.contains('open')){
			openMenu();
		}else{
			closeMenu();
		}
	}
	function openMenu(){
		btn_menu.classList.add("open");
		header.classList.add("open");
	}
	function closeMenu(){
		btn_menu.classList.remove("open");
		header.classList.remove("open");
	}
	for(let i = 0; i < btn_menu_item.length; i++) {
    	btn_menu_item[i].addEventListener('click', closeMenu, false);
	}

	btn_start.addEventListener('click', startGame, false);
	function startGame(){
		wrapper.classList.add("hide");
		game.classList.remove("hide");
		setTimeout(function(){
			game.classList.add("hide");
			header.classList.remove("banish");
		},1000);
	}

	btn_end.addEventListener('click', endGame, false);
	function endGame(){
		game.classList.add("hide");
		header.classList.add("banish");
		wrapper.classList.remove("hide");
	}


	function getUA(){
		var o = {"ua":"","sp":false,"machine":""};
		var strUA = "";
		var strVer = "";
		strUA = navigator.userAgent.toLowerCase();
		strVer = navigator.appVersion.toLowerCase();
		if(strUA.indexOf("edge") != -1){
			o["ua"] = "edge";
			o["ver"] = '';
			return o;
		}
		if(strUA.indexOf("chrome") != -1){
			o["ua"] = "chrome";
			if(strUA.indexOf("android") != -1){
				o["machine"] = "android";
				o["sp"] = true;
			}
			if (strUA.indexOf("edge") != -1) {
				o["ua"] = "edge";
			}
		}else if(strUA.indexOf("safari") != -1){
			o["ua"] = "safari";
			if(strUA.indexOf("iphone") != -1){
				o["machine"] = "iphone";
				o["sp"] = true;
			}else if(strUA.indexOf("ipad") != -1){
				o["machine"] = "ipad";
				o["sp"] = true;
			}else if(strUA.indexOf("ipod") != -1){
				o["machine"] = "ipod";
				o["sp"] = true;
			}else if(strUA.indexOf("android") != -1){
				o["machine"] = "android";
				o["ua"] = "android";
				o["sp"] = true;
			}else if ('ontouchend' in document) {
				o["machine"] = "ipad";
				o["sp"] = true;
			} 
		}else if(strUA.indexOf("firefox") != -1){
			o["ua"] = "firefox";
		}else if(strUA.indexOf("opera") != -1){
			o["ua"] = "opera";
		}else if(strUA.indexOf("netscape") != -1){
			o["ua"] = "netscape";
		}else if(strUA.indexOf("msie") != -1){
			o["ua"] = "ie";
			  if (strVer.indexOf("msie 6.") != -1) {
				  o["ver"] = '6';
			  } else if (strVer.indexOf("msie 7.") != -1) {
				  o["ver"] = '7';
			  } else if (strVer.indexOf("msie 8.") != -1) {
				  o["ver"] = '8';
			  } else if (strVer.indexOf("msie 9.") != -1) {
				  o["ver"] = '9';
			  } else if (strVer.indexOf("msie 10.") != -1) {
				  o["ver"] = '10';
			  }  else {
				return 'other';
			  }
		}else if(strUA.indexOf("trident") != -1){
			o["ua"] = "ie";
			o["ver"] = '11';
		}else if(strUA.indexOf("mozilla/4") != -1){
			o["ua"] = "mozilla/4";
		}
		if(strUA.indexOf("windows") != -1){
			o["machine"] = "win";
		}else if(strUA.indexOf("macintosh") != -1){
			o["machine"] = "mac";
		}
		if(strUA.indexOf("fban") != -1){
			o["sp"] = true;
		}
		if(strUA.indexOf("twitter") != -1){
			o["sp"] = true;
		}
		if(strUA.indexOf("iphone") != -1){
			o["sp"] = true;
		}
		return o;
	}
}